export const questions = [
    {
        category: 'Strategy',
        color: '#f56565',
        questions: [
            {
                key: '1.1',
                label: 'Do you have a Strategic Plan/Growth Strategy?',
                minLabel: 'I do not have a formalised plan for my business',
                maxLabel: 'I have a clearly defined business strategy and documented business plan for at least three years',
            },
            {
                key: '1.2',
                label: 'Do you understand the strengths and weaknesses of your business?',
                minLabel: 'No, I do not have a clear picture of this',
                maxLabel: 'I have a strong understanding of my business’s strengths and weaknesses',
            },
            {
                key: '1.3',
                label: 'Do you understand how well your business is performing?',
                minLabel: 'No, I don’t have a clear picture of this',
                maxLabel: 'I regularly review performance and progress and drive continuous improvement'
            },
            {
                key: '1.4',
                label: 'Do you feel confident you could grow your turnover by at least 20% within the next 12 months?',
                minLabel: 'No, I don’t feel confident at all',
                maxLabel: 'I’m on track to grow my turnover by at least 20% within the next year'
            }
        ]
    },
    {
        category: 'Operations',
        color: '#ed8936',
        questions: [
            { 
                key: '2.1', 
                label: 'How efficiently do you feel you are using your time?',
                minLabel: 'I often run out of time to complete essential tasks in my business',
                maxLabel: 'I have efficient processes in place to ensure I am maximising the use of my time'
            },
            { 
                key: '2.2', 
                label: 'How effective are your relationships with your suppliers and partners?',
                minLabel: 'My suppliers or partners often let me down',
                maxLabel: 'I have strong and mutually beneficial relationships throughout my supply chain and partnerships'
            },
            { 
                key: '2.3', 
                label: 'Are your premises and equipment sufficient to deliver your business plans?',
                minLabel: 'No, they are not at all',
                maxLabel: 'I am confident that my premises and equipment are sufficient to deliver my business plans'
            },
            { 
                key: '2.4', 
                label: 'Are energy efficiency and sustainability important to your business?',
                minLabel: 'I do not consider these important to my business',
                maxLabel: 'I strongly consider energy efficiency and sustainability to be important for my business'
            }
        ]
    },
    {
        category: 'People',
        color: '#ecc94b',
        questions: [
            {
                key: '3.1',
                label: 'Does your business have capacity to meet the needs of your customers?',
                minLabel: 'I have had to turn work down due to capacity',
                maxLabel: 'Yes, my business has capacity to meet the needs of my customers'
            },
            {
                key: '3.2',
                label: 'How well do you feel you understand the rules and regulations around employing people?',
                minLabel: 'I don’t feel I have sufficient knowledge',
                maxLabel: 'I’m confident in my understanding of these rules and regulations'
            },
            {
                key: '3.3',
                label: 'How confident would you feel in recruiting new staff members?',
                minLabel: 'I don’t feel confident at all',
                maxLabel: 'I feel confident in recruiting staff members, if required'
            },
            {
                key: '3.4',
                label: 'How confident do you feel about your management and leadership skills if you were to take on an employee now?',
                minLabel: 'I do not feel confident at all',
                maxLabel: 'I feel very confident about my skills in managing and leading staff'
            },
        ]
    },
    {
        category: 'Finance',
        color: '#48bb78',
        questions: [
            {
                key: '4.1',
                label: 'Are you confident with managing your business’s finances?',
                minLabel: 'I have no confidence in my financial planning or management',
                maxLabel: 'I have a thorough and robust financial management approach'
            },
            {
                key: '4.2',
                label: 'Are you confident that you will have or will be able to access the finance or funding needed to deliver your business plans?',
                minLabel: 'No, I’m not confident at all',
                maxLabel: 'Yes, I have the finance I need or I know where I can access the finance I need to deliver my business plans'
            },
            {
                key: '4.3',
                label: 'How well do you understand the profitability of your different products, services, markets and customers?',
                minLabel: 'I have very little awareness of what areas my business is most profitable in',
                maxLabel: 'I have a deep understanding of all of these'
            },
            {
                key: '4.4',
                label: 'How ambitious are your growth plans for the business?',
                minLabel: 'I am happy with current levels of turnover and profit',
                maxLabel: 'I have ambitions to dramatically grow our business over the coming few years'
            },
        ]
    },
    {
        category: 'Markets & Customers',
        color: '#ed64a6',
        questions: [
            {
                key: '5.1',
                label: 'How well do you understand your business’s position in the market?',
                minLabel: 'I’m aware of my competitors but I do not have a deep understanding of them',
                maxLabel: 'I have a thorough understanding of my competitors and I understand my relative strengths and weaknesses'
            },
            {
                key: '5.2',
                label: 'How important to you is entering new markets and generating income from them?',
                minLabel: 'All of my income plans relate to my current markets',
                maxLabel: 'I have clear targets for entering new markets and regularly grow my customer base from these'
            },
            {
                key: '5.3',
                label: 'How deeply do you understand the values and requirements of your customers?',
                minLabel: 'I have a very limited understanding of my customers',
                maxLabel: 'I undertake careful customer segmentation, analysis and communication to ensure I deeply understand who my customers are and why they buy'
            },
            {
                key: '5.4',
                label: 'How would you describe the market in which you operate?',
                minLabel: 'I am operating in a declining market',
                maxLabel: 'I am in a fast-growing market'
            },
        ]
    },
    {
        category: 'Sales & Marketing',
        color: '#4299e1',
        questions: [
            {
                key: '6.1',
                label: 'How structured is your sales effort?',
                minLabel: 'I have no sales targets, plans or resources for generating and securing sales',
                maxLabel: 'I have detailed targets, and focused plans and resources for generating and securing sales'
            },
            {
                key: '6.2',
                label: 'Are you confident that you are communicating the right messages through the right marketing channels to reach the right customers?',
                minLabel: 'No, I am not confident at all',
                maxLabel: 'I’m confident that I am communicating the right messages through the right channels to reach the right customers'
            },
            {
                key: '6.3',
                label: 'How well do you understand the impact that your sales and marketing have on your business?',
                minLabel: 'I do not know what impact my marketing and sales activities have',
                maxLabel: 'I undertake detailed analysis to identify the methods, media and marketing techniques that work for us'
            },
            {
                key: '6.4',
                label: 'Do you have a marketing strategy for your business?',
                minLabel: 'I don’t understand how best to create a brand',
                maxLabel: 'I have a clear and distinctive brand which is recognised by all of my stakeholders and customers'
            },
        ]
    },
    {
        category: 'Innovation',
        color: '#9f7aea',
        questions: [
            {
                key: '7.1',
                label: 'Are you up to date with the latest technologies and able to identify how these can benefit your business?',
                minLabel: 'No, I am not up to date',
                maxLabel: 'Yes, I am always up to date with the latest technologies and I know how these can benefit my business'
            },
            {
                key: '7.2',
                label: 'Are you confident that you are able to identify and adapt to changes in the marketplace?',
                minLabel: 'No, I’m not confident at all',
                maxLabel: 'I am confident that I can identify and adapt to changes in the marketplace'
            },
            {
                key: '7.3',
                label: 'Do you feel the need to introduce a new process, product or service to your business?',
                minLabel: 'I am not interested in introducing a new process, product or service ',
                maxLabel: 'My business will thrive if a new process, product or service is introduced'
            },
            {
                key: '7.4',
                label: 'How confident are you that your business’s IT systems are safe and secure?',
                minLabel: 'I have no IT security in place',
                maxLabel: 'I am fully aware of the IT threats to my business and I have the security in place to manage these'
            },
        ]
    }
]