import { questions } from "./static/questions";
import { profileQuestions } from "./static/profileQuestions";
import { Link, navigate as n } from 'gatsby';
import React from 'react';

const whitelist = ['amoutInvestedToDate', 'amountPlannedToBeInvested', 'annualResearchDevelopmentAmount', 'clientReferenceNumber'];

if (!Array.prototype.flat)
{
    Object.defineProperty(Array.prototype, 'flat',
    {
        value: function(depth = 1, stack = [])
        {
            for (let item of this)
            {
                if (item instanceof Array && depth > 0)
                {
                    item.flat(depth - 1, stack);
                }
                else {
                    stack.push(item);
                }
            }

            return stack;
        }
    });
}

export const getPrevPageUrl = (currentId, section) => {
  var prevPage = "/";
  var prevId = parseInt(currentId) - 1;
  var prevSectionId = questions.indexOf(section);
  if (prevId < 1) {
    prevSectionId -= 1;
    if (prevSectionId >= 0) {
      prevId = questions[prevSectionId].questions.length;
    }
  }
  if (prevSectionId >= 0) {
    prevPage = `/questions/${questions[
      prevSectionId
    ].category.toLowerCase()}/${prevId}`;
  }
  return prevPage;
};

export const getNextPageUrl = (currentId, section) => {
  var nextPage = "/questions/profile";
  var nextId = parseInt(currentId) + 1;
  var nextSectionId = questions.indexOf(section);
  if (nextId > section.questions.length) {
    nextId = 1;
    nextSectionId += 1;
  }
  if (nextSectionId < questions.length) {
    nextPage = `/questions/${questions[
      nextSectionId
    ].category.toLowerCase()}/${nextId}`;
  }
  return nextPage;
};

export const getQueryParam = state => {
  // console.log(JSON.stringify(state));
  let base64string = JSONToBase64(state);
  return `?q=${base64string}`;
};

export const getFormData = async state => {
  // remove non required keys
  delete state.pending;
  delete state.success;
  delete state.showNotes;

  let responseModel = {
    formData: null,
    errorFields: [],
    isValid: true
  }

  const formData = new FormData();
  questions.forEach((section, s) => {
    section.questions.forEach((question, i) => {
      let questionID = `Q ${s + 1}.${i + 1}`;
      let label = `${questionID}: ${question.label}`;
      let nowVal = state.hasOwnProperty(question.key + "-n") ? state[question.key + "-n"] : "-";
      let futureVal = state.hasOwnProperty(question.key + "-f") ? state[question.key + "-f"] : "-";
      formData.append(label, `${nowVal} / ${futureVal}`);
      let noteVal = state.hasOwnProperty(question.key + "-notes") ? state[question.key + "-notes"] : false;
      if(noteVal) {
        formData.append(`${questionID}: Notes`, `${noteVal}`);
      }
    });
  });


  profileQuestions.map((question, i) => {
    let questionID = `Q 8.${i + 1}`;
    let label = `${questionID}: ${question.label}`;
    if(!whitelist.includes(question.key) && (!state.hasOwnProperty(question.key) || !state[question.key])) {
      responseModel.isValid = false;
      // TODO: turn this into a link
      // /questions/profile${getQueryParam(state)}`
      let labelLink = <Link to={`/questions/profile${getQueryParam(state)}`}>{label} <span className="text-blue-500">(view)</span></Link>
      responseModel.errorFields.push(labelLink);
    }
    let nowVal = state.hasOwnProperty(question.key) ? state[question.key] : "-";
    formData.append(label, `${nowVal}`);
  });
  let noteVal = state.hasOwnProperty("notes") ? state["notes"] : false;
  if(noteVal) {
    formData.append(`Q 8.0: Notes`, `${noteVal}`);
  }
  // var _questions = questions.map(o => o.questions.map(q => { return {[q.key]: q.label}}));
  // _questions = _questions.flat();
  // _questions.unshift(profileQuestions.map(q => { return {[q.key]: q.label}}));
  // var allQuestions = Object.assign(..._questions.flat());
  // for (let name in state) {
  //   // lookup question text
  //   let key = name.split("-");
  //   let label = allQuestions[key[0]];
  //   if(label)
  //   {
  //     // let formattedLabel = toCamelCaseString(label);
  //     let formattedLabel = label;
  //     if(key.length > 1)
  //     {
  //       let prop = key[key.length - 1];
  //       let prepender = (prop === "n") ? "NOW-" : "FUTURE-";
  //       formattedLabel = prepender+formattedLabel;
  //     }
  //     formData.append(formattedLabel, state[name]);
  //   }
  // }
  // Get latest form data
  const latestUri = window.location.href.split('?')[0] + getQueryParam(state);
  const encodedUri = encodeURI(latestUri);
  formData.append("URL", encodedUri);
  // const response = await fetch(`https://cors-anywhere.herokuapp.com/https://tinyurl.com/api-create.php?url=${encodeURI(window.location.href)}`);
  const response = await fetch(`https://tinyurl.com/api-create.php?url=${encodedUri}`);
  const tinyUrl = await response.text();
  formData.append("TinyURL", encodeURI(tinyUrl));

  responseModel.formData = formData;
  return responseModel;
};

export const buildModel = base64Model => {
  // decode model
  var decodedModel = base64Model ? Base64ToJSON(base64Model) : {};
  var nestedModels = questions.map((section, s) => {
    return section.questions.map((question, q) => {
      let response = {};
      // response['key'] = question.key;
      // if(`${question.key}-n` in decodedModel) {
      //   console.log(decodedModel[`${question.key}-n`])
      // }
      response[`${question.key}-n`] =
        `${question.key}-n` in decodedModel
          ? decodedModel[`${question.key}-n`]
          : 5;
      response[`${question.key}-f`] =
        `${question.key}-f` in decodedModel
          ? decodedModel[`${question.key}-f`]
          : 5;
      response[`${question.key}-notes`] =
        `${question.key}-notes` in decodedModel
          ? decodedModel[`${question.key}-notes`]
          : "";
      return response;
    });
  });
  var model = nestedModels.flat();
  var response = Object.assign(...model);
  // add custom fields
  console.log(">>> building submissionID");
  if("submissionId" in decodedModel) {
    response.submissionId = decodedModel.submissionId;
  }
  response.notes = "notes" in decodedModel ? decodedModel.notes : "";
  response.name = "name" in decodedModel ? decodedModel.name : "";
  response.businessName =
    "businessName" in decodedModel ? decodedModel.businessName : "";
  response.clientReferenceNumber =
    "clientReferenceNumber" in decodedModel
      ? decodedModel.clientReferenceNumber
      : "";
  response.businessPartnerDetails =
    "businessPartnerDetails" in decodedModel
      ? decodedModel.businessPartnerDetails
      : "";
  response.amoutInvestedToDate =
    "amoutInvestedToDate" in decodedModel
      ? decodedModel.amoutInvestedToDate
      : "";
  response.amountPlannedToBeInvested =
    "amountPlannedToBeInvested" in decodedModel
      ? decodedModel.amountPlannedToBeInvested
      : "";
  response.ownFunding =
    "ownFunding" in decodedModel ? decodedModel.ownFunding : "";
  response.annualResearchDevelopmentAmount =
    "annualResearchDevelopmentAmount" in decodedModel
      ? decodedModel.annualResearchDevelopmentAmount
      : "";
  response.processInnovation =
    "processInnovation" in decodedModel ? decodedModel.processInnovation : "";
  response.communityInvolvement =
    "communityInvolvement" in decodedModel
      ? decodedModel.communityInvolvement
      : "";
  response.currentEmployeeDetails =
    "currentEmployeeDetails" in decodedModel
      ? decodedModel.currentEmployeeDetails
      : "";
  response.employeeOpportunity =
    "employeeOpportunity" in decodedModel
      ? decodedModel.employeeOpportunity
      : "";
  response.courseInterest =
    "courseInterest" in decodedModel ? decodedModel.courseInterest : "";

  // console.log(JSON.stringify(response));
  return response;
};

export const JSONToBase64 = jsonObject => {
  // var encoded = btoa(JSON.stringify(jsonObject));
  var encoded = Buffer.from(JSON.stringify(jsonObject)).toString('base64');
  return encoded;
};

export const Base64ToJSON = base64 => {
  //var actual = JSON.parse(atob(base64));
  var actual = JSON.parse(Buffer.from(base64, 'base64').toString());
  return actual;
};

// util function to convert the input to string type
function convertToString(input) {
  if (input) {
    if (typeof input === "string") {
      return input;
    }
    return String(input);
  }
  return "";
}

// convert string to words
function toWords(input) {
  input = convertToString(input);
  var regex = /[A-Z\xC0-\xD6\xD8-\xDE]?[a-z\xDF-\xF6\xF8-\xFF]+|[A-Z\xC0-\xD6\xD8-\xDE]+(?![a-z\xDF-\xF6\xF8-\xFF])|\d+/g;
  return input.match(regex);
}

// convert the input array to camel case
function toCamelCase(inputArray) {
  let result = "";
  for (let i = 0, len = inputArray.length; i < len; i++) {
    let currentStr = inputArray[i];
    let tempStr = currentStr.toLowerCase();
    if (i != 0) {
      // convert first letter to upper case (the word is in lowercase)
      tempStr = tempStr.substr(0, 1).toUpperCase() + tempStr.substr(1);
    }
    result += tempStr;
  }
  return result;
}

// this function call all other functions

export function toCamelCaseString(input) {
  let words = toWords(input);
  return toCamelCase(words);
}


export function navigate(route) {
  if (typeof window !== `undefined`) {
    n(route);
  }
}